import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import ImagePortrait from "./imagePortrait"
import { FaGraduationCap } from "react-icons/fa"
const presentationHome = () => (
  <Wrapper className="submenu">
    <Col1>
      <ImagePortrait />
      <Button to="/chirurgien-main-coude-epaule-paris/">
        <FaGraduationCap
          css={css`
            transform: translateY(2px);
            margin-right: 4px;
          `}
        />
        Titres et diplômes
      </Button>
    </Col1>

    <Col2>
      <H1>
        Chirurgien orthopédiste à Paris, spécialiste exclusif en chirurgie de la
        main, du coude et de l’épaule
      </H1>

      <p>
        Chirurgien orthopédiste, je suis spécialiste de la chirurgie de la main,
        de la microchirurgie, de la chirurgie arthroscopique et de la{" "}
        <Link to="/chirurgie-du-sport/">chirurgie du sport</Link>. Depuis{" "}
        <strong>plus de 20&nbsp;ans</strong>, j’exerce de façon exclusive
        la chirurgie de la main, du poignet, du coude et de l'épaule.
      </p>

      <H2>Une approche «&nbsp;sur mesure&nbsp;» de la chirurgie</H2>

      <p>
        Je privilégie une approche «&nbsp;<strong>sur mesure</strong>&nbsp;»,
        recherchant à la fois la solution techniquement la plus pointue et la
        mieux adaptée aux attentes et au profil de mes patients. J’allie mon
        expérience de la prise en charge des sportifs de haut niveau à celle de
        la chirurgie réparatrice et de la microchirurgie afin de proposer une{" "}
        <strong>réponse personnalisée</strong> à chacun de mes patients.
      </p>

      <H2>Un résultat fonctionnel et esthétique optimal</H2>
      <p>
        Dans ma pratique de chirurgien orthopédique, j’utilise les techniques
        chirgicales
        <strong> les plus récentes</strong> et les{" "}
        <strong>moins invasives</strong> (
        <Link to="/techniques/microchirurgie/">microchirurgie</Link>, l’
        <Link to="/techniques/arthroscopie/">arthroscopie</Link>) afin de
        limiter au maximum les douleurs, faciliter une récupération
        post-opératoire la plus rapide possible, tout en apportant le meilleur
        résultat fonctionnel et esthétique.
      </p>
      <p>
        J’assure mes consultations ainsi que mon activité chirurgicale au sein
        de <strong>centres hyperspécialisés</strong> dans la chirurgie
        orthopédique et la chirurgie du sport situé à Paris&nbsp;: l'
        <Link to="/consultation/">Espace Médical Vauban</Link> (Paris 7) et la{" "}
        <Link to="/hospitalisation/">Clinique du Sport</Link> (Paris 5). Je
        dispose ainsi du matériel le plus moderne, en collaboration avec des
        confrères médecins et chirurgiens également spécialisés dans la
        traumatologie, la médecine du sport et la chirurgie orthopédique.
      </p>
      <p
        css={css`
          text-align: right;
          font-weight: 600;
          margin-top: 20px;
          @media (max-width: 678px) {
            font-size: 0.92em;
            margin: 10px 0 20px;
          }
        `}
      >
        Dr Philippe Roure - Chirurgien orthopédiste du membre supérieur à Paris
      </p>
    </Col2>
  </Wrapper>
)

const Wrapper = styled.section`
  display: flex;
  background-color: white;
  border-radius: 5px;
  margin: 2.5em auto 0.5em;
  padding: 1.75em 1.2em 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    flex-wrap: wrap;
    padding: 0.9em;
  }
`

const Col1 = styled.div`
  flex: 25%;
  padding: 10px 10px 0 0;
  @media (max-width: 678px) {
    order: 2;
    flex-basis: 100%;
    padding: 0;
  }
`

const Col2 = styled.div`
  flex: 75%;
  padding-left: 12px;
  @media (max-width: 678px) {
    order: 1;
    flex-basis: 100%;
    padding: 0;
  }
`

const H1 = styled.h1`
  text-align: left;
  font-size: 1.7em;
  line-height: 1.4em;
  @media (max-width: 678px) {
    font-size: 1.5em;
    line-height: 1.3em;
  }
`

const H2 = styled.h2`
  font-size: 1.18em;
  opacity: 0.85;
  line-height: 1.5em;
  margin-top: 5px;
`

const Button = styled(Link)`
  display: block;
  clear: both;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  padding: 0.6em 1.2em;
  background-color: #d69c31;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 0.92em;
  letter-spacing: 0.035em;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  :hover,
  :focus {
    color: white;
    background-color: rgba(44, 44, 44, 0.96);
    outline: none;
    transition: all 0.15s ease-in-out;
  }
  svg {
    font-size: 1.2em;
  }
  @media (max-width: 1024px) {
    padding: 0.5em 0.8em;
  }
`

export default presentationHome
