import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import BannerHome from "../components/banner-home"
import PresentationHome from "../components/presentationHome"
import InterventionsHome from "../components/interventionsHome"
import ActusHome from "../components/actus-home"
import ContactHome from "../components/contact-home"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout pathEn="/en/">
    <SEO
      title="Dr Philippe Roure - Chirurgien orthopédiste à Paris"
      description="Chirurgien orthopédiste à Paris 7, le Dr Roure est spécialiste de la chirurgie de la main, du coude et de l’épaule ainsi que de la chirurgie du sport."
    />
    <Helmet>
      <meta property="og:url" content="https://www.docteurphilipperoure.com/" />
      <meta
        property="og:title"
        content="Dr Philippe Roure - Chirurgien orthopédiste à Paris"
      />
      <meta
        property="og:description"
        content="Chirurgien orthopédiste à Paris 7, le Dr Roure est spécialiste de la chirurgie de la main, du coude et de l’épaule ainsi que de la chirurgie du sport.."
      />
      <meta property="og:locale" content="fr_FR" />
      <meta
        property="og:image"
        content="https://www.docteurphilipperoure.com/dr-philippe-roure.jpg"
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="fb:app_id" content="1086796511711258" />
      <link
        rel="alternate"
        hreflang="en"
        href={`https://www.docteurphilipperoure.com/en/`}
      />
    </Helmet>
    <BannerHome />
    <PresentationHome />

    <InterventionsHome />
    <ActusHome />
    <ContactHome />
    <ContactForm
      about="les opérations de chirugie orthopédique du membre supérieur (chirurgie de la main, du coude, de l'épaule, du poignet)"
      title="Page d'accueil"
    />
  </Layout>
)

export default IndexPage
