import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css, Global } from "@emotion/react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const BannerHome = () => (
  <StaticQuery
    query={graphql`
      query {
        chirurgien: file(relativePath: { eq: "portrait-bandeau-roure.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        microchirurgie: file(
          relativePath: { eq: "microchirurgie-arthroscopie.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chirurgiesport: file(
          relativePath: { eq: "chirurgie-sport-squash.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        invalides: file(
          relativePath: { eq: "chirurgien-orthopedique-paris-7.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Global
          styles={css`
            .slick-dots li button:before {
              font-size: 8px;
            }
            .slick-dots li.slick-active button:before {
              color: #d69c31;
            }
          `}
        />
        <Slider {...settings}>
          <Slide>
            <Link to="/chirurgien-main-coude-epaule-paris/">
              <Img
                fluid={data.chirurgien.childImageSharp.fluid}
                alt="Dr Philippe Roure, chirurgien orthopédiste à Paris, spécialiste de la chirurgie de la main et du membre supérieur"
                loading="eager"
              />
              <Caption>
                <Title>
                  Chirurgien de la main, du coude et l'épaule
                  <span
                    css={css`
                      @media (max-width: 1024px) {
                        display: none;
                      }
                    `}
                  >
                    {" "}
                    à Paris
                  </span>
                </Title>
                <SubTitle>
                  Spécialisé en chirurgie orthopédique et réparatrice, le
                  Dr&nbsp;Philippe Roure exerce de manière exclusive la
                  chirurgie de la main et du membre supérieur (bras, coude,
                  épaule).
                </SubTitle>
              </Caption>
            </Link>
          </Slide>
          <Slide>
            <Link to="/techniques/">
              <Img
                fluid={data.microchirurgie.childImageSharp.fluid}
                alt="Dr Philippe Roure, chirurgien orthopédiste à Paris, spécialiste de la chirurgie de l'épaule"
                loading="eager"
              />
              <Caption>
                <Title>Microchirurgie & Arthroscopie</Title>
                <SubTitle>
                  Le Dr Roure utilise les techniques les plus récentes et les
                  moins invasives, afin de limiter les suites opératoires tout
                  en apportant un résultat fonctionnel et esthétique optimal.
                </SubTitle>
              </Caption>
            </Link>
          </Slide>
          <Slide>
            <Link to="/chirurgie-du-sport/">
              <Img
                fluid={data.chirurgiesport.childImageSharp.fluid}
                alt="Dr Philippe Roure, spécialiste de la chirurgie du sport à Paris"
                loading="eager"
              />
              <Caption>
                <Title>Chirurgie du sport</Title>
                <SubTitle>
                  Les progrès de la chirurgie orthopédique permettent d’apporter
                  une réponse aux blessures de plus en plus fiable, avec une
                  reprise de plus en plus rapide de la pratique sportive.
                </SubTitle>
              </Caption>
            </Link>
          </Slide>
          <Slide>
            <Link to="/contact/">
              <Img
                fluid={data.invalides.childImageSharp.fluid}
                alt="Dr Philippe Roure, chirurgien orthopédiste à Paris, spécialiste de la chirurgie de la main et du membre supérieur"
              />
              <Caption>
                <Title>Espace Médical Vauban</Title>
                <SubTitle>
                  Situé dans le 7<sup>e</sup> arrondissement de Paris, l'Espace
                  Médical Vauban regroupe des spécialistes de la chirurgie
                  orthopédique, de la chirurgie du sport et des traitements de
                  l'arthrose.
                </SubTitle>
              </Caption>
            </Link>
          </Slide>
        </Slider>
      </Wrapper>
    )}
  />
)
export default BannerHome

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3500,
  pauseOnHover: true,
  arrows: false,
}

const Wrapper = styled.div`
  position: relative;
`
const Slide = styled.div`
  position: relative;
  border-radius: 4px;
`

const Caption = styled.div`
  position: absolute;
  top: 57%;
  left: 30px;
  margin: 0;
  width: 525px;
  border-radius: 4px;
  display: inline-block;
  color: white;
  font-weight: 300;
  letter-spacing: 0.03em;
  background-color: rgba(214, 156, 49, 0.9);
  padding: 1em 1em;
  @media (max-width: 1024px) {
    top: 49%;
    padding: 0.5em 0.5em;
  }
  @media (max-width: 678px) {
    bottom: 0;
    left: 0;
    top: auto;
    padding: 0.25em 0.25em;
    max-width: fit-content;
    min-width: 100%;
    border-radius: 0;
  }
`

const Title = styled.div`
  font-size: 2em;
  letter-spacing: 0.02em;
  margin: 0.2em 0 0.3em;
  line-height: 1.2em;
  @media (max-width: 1024px) {
    font-size: 1.5em;
  }
  @media (max-width: 678px) {
    font-size: 1.05em;
    text-align: center;
    box-sizing: border-box;
  }
`

const SubTitle = styled.div`
  font-size: 1.15em;
  line-height: 1.5em;
  @media (max-width: 678px) {
    display: none;
  }
`
